import request from '@/utils/request'

export const getEmailConfirmationInfo = (customerId: string) =>
  request({
    url: `/market/v1/customers/${customerId}/email_status?subject=confirmation`,
    method: 'get'
  })

export const approvementCheck = (customerId: string) =>
  request({
    url: `/market/v1/customers/${customerId}/approvement_check`,
    method: 'post'
  })
